<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-21 16:24:15
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-21 17:51:24
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/component/SettleSts/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <span class="settle-sts-box" :class="{[stsBacColorClass]: true}">
    {{ stsText }}
  </span>
</template>

<script>
import { stsList } from '../../list'
export default {
  name: 'SettleSts',
  data () {
    return {
      stsList
    }
  },
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stsText () {
      return this.stsList.find((item) => item.id === this.row.sts)?.name || '--'
    },
    stsBacColorClass () {
      const successList = [5]
      const failList = [6]
      if (successList.includes(this.row.sts)) {
        return 'success'
      } else if (failList.includes(this.row.sts)) {
        return 'fail'
      } else {
        return 'info'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.settle-sts-box {
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  width: 80px;
  text-align: center;
  &.success{
    background-color: @assisColor;
  }
  &.fail{
    background-color: #5a607f;
  }
  &.info{
    background-color: #F99600;
  }
}
</style>
