export const stsList = [
  {
    name: '待申请',
    id: 1
  },
  {
    name: '待上传发票',
    id: 2
  },
  {
    name: '审核失败',
    id: 3
  },
  {
    name: '结算中',
    id: 4
  },
  {
    name: '结算完成',
    id: 5
  },
  {
    name: '结算失败',
    id: 6
  }
]
